@theme_color   : var(--ant-primary-color);
// @theme_color: #0084f6;
@title_b       : #f2f3f5; //标题栏背景色
@side_b        : rgba(0, 0, 0, 0.04); //描边 & 模块分割底色
@side_strong   : #cacaca; //按钮描边

@divider_b    : #e5e5e5; //divider分割线
@hint_b       : rgba(0, 0, 0, 0.32); //hint & disable
@icon_b       : #666666; //icon & secondary
@text_b       : #1d2129; //text
@divider_w    : rgba(white, 0.12); //divider
@hint_w       : rgba(white, 0.3); //hint disable
@icon_w       : rgba(white, 0.7); //icon secondary
@text_w       : rgb(255, 255, 255); //text
@success_green: #03b266;
@fail_red     : #ff4444;
@warn_yellow  : #fc7e36;
@common_blue  : @theme_color;
@disable      : #898183;
@placeholder_b: #cccccc;

// ----------------------------------基础布局样式----------------------------------------
.ti2 {
  text-indent: 8px;
}

// 内边距
.createPadding(@n, @i: 2) when (@i =< @n) {
  .p@{i} {
    padding: @i * 1px;
  }

  .pt@{i} {
    padding-top: @i * 1px;
  }

  .pl@{i} {
    padding-left: @i * 1px;
  }

  .pb@{i} {
    padding-bottom: @i * 1px;
  }

  .pr@{i} {
    padding-right: @i * 1px;
  }

  .ptb@{i} {
    padding-top   : @i * 1px;
    padding-bottom: @i * 1px;
  }

  .plr@{i} {
    padding-left : @i * 1px;
    padding-right: @i * 1px;
  }

  .createPadding(@n, (@i + 2));
}

.createPadding(128);

// 外边距
.createMargin(@n, @i: 2) when (@i =< @n) {
  .m@{i} {
    margin: @i * 1px;
  }

  .mt@{i} {
    margin-top: @i * 1px;
  }

  .ml@{i} {
    margin-left: @i * 1px;
  }

  .mb@{i} {
    margin-bottom: @i * 1px;
  }

  .mr@{i} {
    margin-right: @i * 1px;
  }

  .mtb@{i} {
    margin-top   : @i * 1px;
    margin-bottom: @i * 1px;
  }

  .mlr@{i} {
    margin-left : @i * 1px;
    margin-right: @i * 1px;
  }

  .createMargin(@n, (@i + 2));
}

.createMargin(128);

// 高宽
.createWH(@n, @i: 1) when (@i =< @n) {
  @name: @i*32;

  .w@{name} {
    width: @i * 32px;
  }

  .h@{name} {
    height: @i * 32px;
  }

  .createWH(@n, (@i + 1));
}

.createWH(30);

.w33 {
  width: 33%;
}

.w50 {
  width: 50%;
}

.br3 {
  border-radius: 3px;
}

// 字体
.fs0 {
  font-size: 0px;
}

.createFS(@n, @i: 12) when (@i =< @n) {
  .fs@{i} {
    font-size: @i * 1px;
  }

  .createFS(@n, (@i + 2));
}

.createFS(60);

// 层级系列
.z-1 {
  z-index: -1;
}

.z-99 {
  z-index: -99;
}

.z1 {
  z-index: 1;
}

.z9 {
  z-index: 10;
}

.z10 {
  z-index: 10;
}

.z99 {
  z-index: 99;
}

.z500 {
  z-index: 500;
}

.z999 {
  z-index: 999;
}

//position
.p-r {
  position: relative;
}

.p-f {
  position: fixed;
}

.p-a {
  position: absolute;
}

.p-s {
  position: sticky;
}

.l0 {
  left: 0;
}

.r0 {
  right: 0;
}

.t0 {
  top: 0;
}

.l-10000 {
  left: -10000px;
}

.b0 {
  bottom: 0;
}

//overflow
.ov-h {
  overflow: hidden;
}

.ov-a {
  overflow: auto;
}

.ov-xa {
  overflow-x: auto;
}

.ov-ya {
  overflow-y: auto;
}

// display系列
.d-il {
  display: inline-block;
}

.d-b {
  display: block;
}

.d-i {
  display: inline;
}

.d-n {
  display: none;
}

.d-f {
  display: flex;
}

.w-full {
  width: 100%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

// 居中系列
.ta-r {
  text-align: right;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-j {
  text-align: justify;
}

.va-t {
  vertical-align: top;
}

.va-m {
  vertical-align: middle;
}

.va-b {
  vertical-align: bottom;
}

.v-h {
  visibility: hidden;
}

.v-v {
  visibility: visible;
}

// 定位系列
.pos-a {
  position: absolute;
}

.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

// flex布局系列
.flex1 {
  // @include flex();
  flex: 1;
}

.flex2 {
  // @include flex(2);
  flex: 2;
}

.flex3 {
  // @include flex(3);
  flex: 3;
}

.flex4 {
  // @include flex(4)
  flex: 4;
}

.ac {
  align-items: center;
}

.at {
  align-items: start;
}

.as {
  align-items: stretch;
}

.ai-b {
  align-items: baseline;
}

.fw {
  flex-wrap: wrap;
}

.fd-c {
  flex-direction: column;
}

.jc-b {
  justify-content: space-between;
}

.jc {
  justify-content: center;
}

.jc-r {
  justify-content: flex-end;
}

.jc-l {
  justify-content: flex-start;
}

.s0 {
  flex-shrink: 0;
}

// 边框系列
.bor {
  border: solid 1px;
}

.bor-t {
  border-top: solid 1px;
}

.bor-b {
  border-bottom: solid 1px;
}

.bor-l {
  border-left: solid 1px;
}

.bor-r {
  border-right: solid 1px;
}

// 背景系列
.bg-w {
  background-color: #fff;
}

.bg-b {
  // background-color: rgba(@color:black, @alpha: .8);
  background-color: #1d2129;
}

.bg-module {
  background: rgba(0, 0, 0, 0.02);
  ; //模块分割底色
}

.bg-title {
  background-color: @title_b; //title 底色
}

.bg-disable {
  background-color: @disable;
}

.bg-tempt {
  background-color: rgba(black, 0.7);
}

.bg-placeholder {
  background-color: @placeholder_b;
}

.bg-divider {
  background-color: @divider_b;
}

.bg-hint {
  background-color: @hint_b;
}

.bg-icon {
  background-color: @icon_b;
}

.bg-text {
  background-color: @text_b;
}

.bg-side {
  background-color: @side_b;
}

.bg-side-s {
  background-color: @side_strong;
}

.tp {
  background-color: transparent;
}

//渐变色
.bg-mei-red {
  background-image: linear-gradient(-117deg, #d5466c 0%, #fa5b52 100%);
}

.bg-mei-yel {
  background-image: linear-gradient(-138deg, #fcb719 0%, #f6a346 100%);
}

.bg-blue {
  background-color: @common_blue;
}

.bg-theme {
  background-color: @theme_color;
}

// 状态色
.bg-success {
  background-color: @theme_color  !important;
}

.bg-fail {
  background-color: @fail_red;
}

.bg-warn {
  background-color: @warn_yellow;
}

// 颜色系列
.c-divider-b {
  color: @divider_b;
}

//divider
.c-hint-b {
  color: @hint_b;
}

.c-text2-b {
  color: rgba(0, 0, 0, 0.52);
}

//hint disable
.c-icon-b {
  color: @icon_b;
}

//icon secondary
.c-text-b {
  color: @text_b;
}

//text
.c-divider-w {
  color: @divider_w;
}

//divider
.c-hint-w {
  color: @hint_w;
}

//hint disable
.c-icon-w {
  color: @icon_w;
}

//icon secondary
.c-text-w {
  color: @text_w;
}

//text
.c-blue {
  color: @common_blue;
}

// 状态色
.c-success {
  color: @success_green;
}

.c-fail {
  color: @fail_red;
}

.c-warn {
  color: @warn_yellow;
}

.c-placeholder {
  color: @placeholder_b;
}

//色
.c-red {
  color: @fail_red;
}

.c-theme {
  color: @theme_color;
}

.b-theme {
  border-color: @theme_color;
}

.b-fail {
  border-color: @fail_red;
}

.b-warn {
  border-color: @warn_yellow;
}

.b-side {
  border-color: @side_b;
}

.b-side-s {
  border-color: @side_strong;
}

.op0 {
  opacity: 0;
}

.cp {
  cursor: pointer;
}

// tool
// 0.5px
.thin {
  position: relative;

  &::after {
    position        : absolute;
    left            : 0;
    top             : 0;
    border          : 1px solid;
    width           : 200%;
    height          : 200%;
    content         : "";
    transform       : scale(0.5);
    transform-origin: left top;
  }
}

// 用于全屏居中（容器）
.center-fullscreen {
  display        : flex;
  justify-content: center;
  align-items    : center;
  position       : absolute;
  width          : 100%;
  height         : 100%;
  top            : 0;
  left           : 0;
}

.fullscreen {
  width     : 100vw;
  height    : 100vh;
  box-sizing: border-box;
  overflow  : hidden;
}

.box {
  box-sizing: border-box;
}

.shadow {
  box-shadow: 0px 8px 16px -4px rgba(37, 48, 68, 0.16), 0px 1px 3px 0px rgba(37, 48, 68, 0.12);
}

.shadow-hover {
  &:hover {
    transition: box-shadow 0.3s;
    box-shadow: 0px 8px 16px -4px rgba(37, 48, 68, 0.16), 0px 1px 3px 0px rgba(37, 48, 68, 0.12);
  }
}

.paper {
  background: #fff;
}

//文字限制几多行后就出现省略号
.ellip1 {
  overflow     : hidden;
  text-overflow: ellipsis;
  white-space  : nowrap;
}

.ellip2 {
  display           : -webkit-box;
  overflow          : hidden;
  box-orient        : vertical;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellip3 {
  display           : -webkit-box;
  overflow          : hidden;
  box-orient        : vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.link {
  cursor         : pointer;
  color          : @theme_color;
  text-decoration: none;
}

.mark {
  background-color: rgba(0, 0, 0, 0.2);
}

.link-disable {
  cursor         : not-allowed;
  color          : @hint_b;
  text-decoration: none;
}

.btn-tag-info {
  display         : inline-block;
  padding         : 2px 6px 2px 6px;
  color           : @theme_color;
  background-color: rgba(@theme_color, 10%);
  border-radius   : 2px;
  font-size       : 12px;
  font-weight     : normal;
}

.btn-tag-warn {
  display         : inline-block;
  padding         : 2px 6px 2px 6px;
  color           : @warn_yellow;
  background-color: rgba(@warn_yellow, 10%);
  border-radius   : 2px;
  font-size       : 12px;
  font-weight     : normal;
}

.btn-tag-error {
  display         : inline-block;
  padding         : 2px 6px 2px 6px;
  color           : @fail_red;
  background-color: rgba(@fail_red, 10%);
  border-radius   : 2px;
  font-size       : 12px;
  font-weight     : normal;
}

.btn-tag-success {
  display         : inline-block;
  padding         : 2px 6px 2px 6px;
  color           : @success_green;
  background-color: rgba(@success_green, 10%);
  border-radius   : 2px;
  font-size       : 12px;
  font-weight     : normal;
}

.btn-tag-disable {
  display         : inline-block;
  padding         : 2px 6px 2px 6px;
  color           : @disable;
  background-color: rgba(@disable, 10%);
  border-radius   : 2px;
  font-size       : 12px;
  font-weight     : normal;
}

.fadein {
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.divider {
  border-top: 1px solid hsla(0, 0%, 91%, .1);
  width     : 100%;
}