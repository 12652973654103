// :root {
//     --ant-primary-color: #1e1653;
// }

::selection{
    background-color: ~'var(--ant-primary-color)' !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td{
    background-color: ~'var(--ant-primary-1)';
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: ~'var(--ant-primary-color)';
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: ~'var(--ant-primary-color)';
}

.ant-menu-light .ant-menu-submenu-title:hover{
    color: ~'var(--ant-primary-color)';
}

.ant-menu-submenu-selected{
    color: ~'var(--ant-primary-color)';
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ~'var(--ant-primary-1)';
}

.ant-steps-item-process .ant-steps-item-icon{
    border-color: ~'var(--ant-primary-color)';
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background-color: ~'var(--ant-primary-color)';
}

.ant-btn {
    &.ant-btn-primary {
        border-color: ~'var(--ant-primary-color)';
        background-color: ~'var(--ant-primary-color)';
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: ~'var(--ant-primary-1)';
    color: ~'var(--ant-primary-color)';
}

.ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid ~'var(--ant-primary-color)'
}

.ant-menu-item{
    // background-color: ~'var(--ant-primary-color)';
}

.helpDoc{
    background-color: ~'var(--ant-primary-color)';
}

.ant-pagination-item-active{
    border-color: ~'var(--ant-primary-color)';
}

.ant-pagination-item-active a{
    color: ~'var(--ant-primary-color)';
}

.link{
    color: ~'var(--ant-link)';
}

a{
    color: ~'var(--ant-link)';
}

.ant-checkbox-checked .ant-checkbox-inner{
    background-color: ~'var(--ant-primary-color)';
    border-color: ~'var(--ant-primary-color)';
}

.ant-checkbox-checked::after{
    border: 1px solid ~'var(--ant-primary-color)';
}

.mainapp-topmenu > li:hover{
    color: ~'var(--ant-primary-color)';
    background-color:  ~'var(--ant-primary-1)';
}

.mainapp-topmenu .mainapp-item-active{
    color:  ~'var(--ant-primary-color)';
    border-top: 2px solid  ~'var(--ant-primary-color)';
  }