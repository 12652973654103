.loading-cb {
    animation: aniCB 2s infinite ease-in-out;
  }

  @keyframes aniCB {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }